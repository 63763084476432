import {
  Box,
  Grid,
  Heading,
  HStack,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Sidebar from '../Sidebar';
import cursor from '../../../assets/images/cursor.png';
import Loader from '../../Layout/Loader/Loader';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import { DoughnutChart, LineChart } from './Chart';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDashboardStats } from '../../../redux/actions/admin';

const Databox = ({ title, qty, qtyPercentage, profit }) => {
  return (
    <Box
      width={['full', '20%']}
      boxShadow={'-2px 0 10px rgba(107, 70, 193,0.5)'}
      p="8"
      borderRadius="lg"
    >
      <Text children={title} />
      <HStack spacing={'6'}>
        <Text fontSize={'2xl'} fontWeight={'bold'} children={qty} />
        <HStack>
          <Text children={`${qtyPercentage}%`} />
          {profit ? (
            <RiArrowUpLine color="green" />
          ) : (
            <RiArrowDownLine color="red" />
          )}
        </HStack>
      </HStack>

      <Text opacity={'0.6'} children={'Since Last Month'} />
    </Box>
  );
};

const Bar = ({ title, value, profit }) => (
  <Box py={'4'} px={['0', '20']}>
    <Heading size={'sm'} children={title} mb="2" />
    <HStack w={'full'} alignItems="center">
      <Text children={profit ? '0%' : `-${value}%`} />
      <Progress w={'full'} value={profit ? value : 0} colorScheme="purple" />
      <Text children={`${value > 100 ? value : 100}%`} />
    </HStack>
  </Box>
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    stats,
    usersCount,
    subscriptionsCount,
    viewsCount,
    usersProfit,
    subscriptionsProfit,
    viewsProfit,
    usersPercentage,
    subscriptionsPercentage,
    viewsPercentage,
    loading,
  } = useSelector(state => state.admin);

  useEffect(() => {
    dispatch(getAdminDashboardStats());
  }, [dispatch]);

  return (
    <Grid
      css={{
        cursor: `url(${cursor}), default`,
      }}
      minH={'100vh'}
      templateColumns={['1fr', '5fr 1fr']}
    >
      {loading || !stats ? (
        <Loader color="purple.500" />
      ) : (
        <Box boxSizing={'border-box'} py="16" px={['4', '0']}>
          <Text
            textAlign={'center'}
            opacity={0.5}
            children={`Last change was on ${
              String(new Date(stats[11].updatedAt)).split('G')[0]
            }`}
          />

          <Heading
            children="Dashboard"
            ml={['0', '16']}
            mb={'16'}
            textAlign={['center', 'left']}
          />

          <Stack
            direction={['column', 'row']}
            minHeight="24"
            justifyContent={'space-evenly'}
          >
            <Databox
              title={'Views'}
              qty={viewsCount}
              qtyPercentage={viewsPercentage}
              profit={viewsProfit}
            />
            <Databox
              title={'Users'}
              qty={usersCount}
              qtyPercentage={usersPercentage}
              profit={usersProfit}
            />
            <Databox
              title={'Subscriptions'}
              qty={subscriptionsCount}
              qtyPercentage={subscriptionsPercentage}
              profit={subscriptionsProfit}
            />
          </Stack>

          <Box
            m={['0', '16']}
            borderRadius="lg"
            padding={['0', '16']}
            mt={['4', '16']}
            boxShadow={'-2px 0 10px rgba(107, 70, 193,0.5)'}
          >
            <Heading
              textAlign={['center', 'left']}
              size={'md'}
              children={'Views, Users and Subscriptions Graph'}
              pt={['8', '0']}
              marginLeft={['0', '16']}
            />

            <LineChart
              views={stats.map(stat => stat.views)}
              users={stats.map(stat => stat.users)}
              subscriptions={stats.map(stat => stat.subscriptions)}
            />
          </Box>

          <Grid templateColumns={['1fr', '2fr 1fr']}>
            <Box p="4">
              <Heading
                textAlign={['center', 'left']}
                size="md"
                children="Progress Bar"
                my="8"
                ml={['0', '16']}
              />

              <Box>
                <Bar
                  title="Views"
                  value={viewsPercentage}
                  profit={viewsProfit}
                />
                <Bar
                  title="Users"
                  value={usersPercentage}
                  profit={usersProfit}
                />
                <Bar
                  title="Subscriptions"
                  value={subscriptionsPercentage}
                  profit={subscriptionsProfit}
                />
              </Box>
            </Box>

            <Box p={['0', '16']} boxSizing="border-box" py="4">
              <Heading
                textAlign={'center'}
                size="md"
                mb={'4'}
                children="Users"
              />

              <DoughnutChart
                users={[subscriptionsCount, usersCount - subscriptionsCount]}
              />
            </Box>
          </Grid>
        </Box>
      )}
      <Sidebar />
    </Grid>
  );
};

export default Dashboard;
